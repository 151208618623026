import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import { CaseList } from "../../components/caseList";

import doodle from "../../assets/images/services/cms.svg";

const ContentManagementSysteme = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-cms.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="service.cms.link">
      <Seo
        title="Ihr digitaler Erfolg mit einem CMS – Monday Consulting"
        description="Als zertifizierte Implementierungspartner für die Content-Management-Systeme von Crownpeak und CoreMedia nutzen wir unsere jahrelange Erfahrung zu Ihrem Vorteil."
        keywords="Content-Management-Systeme, CoreMedia, Crownpeak, FirstSpirit, CMS, Strapi"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Der Grundstein zum Erfolg"
        subline="Ein geeignetes CMS, das Ihren Anforderungen entspricht, bildet die Basis."
        section="Content-Management-Systeme"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Content-Management-Systeme"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Die Grundlage für Ihren Erfolg im digitalen Geschäft"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Die Grundlage für Ihren Erfolg im digitalen Geschäft</h2>
            <p>
              Ob Greenfield, Relaunch oder umfangreiche Migration – als
              zertifizierte Implementierungspartner für die
              Content-Management-Systeme von Crownpeak und CoreMedia nutzen wir
              unsere jahrelange Erfahrung zu Ihrem Vorteil. Zusätzlich bieten
              wir Ihnen mit Strapi eine Alternative, die sich insbesondere für
              Projekte mit Frontend-Fokus eignet. Das Headless-CMS bietet mit
              modernsten Technologien die Möglichkeit, schnell auf neue Trends
              zu reagieren. Um die Kommunikation mit Ihren Kunden zu verbessern,
              verknüpfen unsere kreativen Experten Stategie-, Design- und
              Entwicklungsexzellenz. Dabei setzen wir immer auf höchste
              Sicherheit, Erreichbarkeit und Performance Ihrer Applikationen.
              Egal ob in der Cloud oder durch eigenes Hosting - wir entwickeln
              immer die passende Lösung für Sie.
            </p>
            <p>
              Als Ihr Partner für die CMS-Implementierung begleiten wir Sie über
              den gesamten Projektzyklus hinweg – sowohl remote als auch vor
              Ort. Dabei decken wir unter anderem die Bereiche Strategie,
              Konzeption, Entwicklung, Integration und Projektmanagement ab und
              unterstützen Sie auch nach erfolgreichem Go-live weiterhin durch
              unseren Customer Care.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Content-Management-Systeme im Detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-cmsexpertise bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              CMS-Expertise
            </h3>
            <p>
              Dank unserer langjährigen Erfahrung in der Implementierung von
              Content-Management-Systemen entwickeln wir für jede Anforderung
              die passende Lösung. Seit 2009 individualisieren wir umfangreiche
              Enterprise-Lösungen auf Basis von CoreMedia und FirstSpirit und
              passen die Content-Management-Systeme an die expliziten
              Anforderungen an.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-coremedia bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              CoreMedia
            </h3>
            <p>
              Als „Gold- und Certified Partner” von CoreMedia entwickeln wir
              maßgeschneiderte Plattformen für Ihren Erfolg. Wir transportieren
              Informationen und vernetzen Menschen und Prozesse. Das agile
              Content-Management-System bietet uns dabei die größtmögliche
              Flexibilität, um all Ihren Anforderungen gerecht zu werden.
            </p>
            <Link to="/leistungen/cms/coremedia-partner" className="btn mt-1">
              CoreMedia Partnerschaft
            </Link>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-firstspirit bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              FirstSpirit
            </h3>
            <p>
              Als „Premier Partner” von Crownpeak gehören wir zu den
              bestausgebildeten Consultants für die FirstSpirit Digital
              Experience Platform (DXP) und stehen Ihnen gern mit unserem
              Spezialwissen – insbesondere bei komplexen Projekten – als
              Implementierungspartner zu Seite.
            </p>
            <Link to="/leistungen/cms/crownpeak-partner" className="btn mt-1">
              FirstSpirit Partnerschaft
            </Link>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-strapi bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Strapi
            </h3>
            <p>
              Neben den Enterprise-Content-Management-Systemen gibt es auch
              leichtgewichtige Alternativen am Markt, die mit modernsten
              Technologien arbeiten und sich optimal individualisieren lassen.
              Unsere Wahl ist das Headless-CMS Strapi – damit ist es unseren
              Experten möglich, in kürzester Zeit eine moderne CMS-Plattform zu
              realisieren.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="eppendorf,dak-gesundheit" />
      </div>

      <ContactPerson
        headline="Dürfen wir Ihnen behilflich sein?"
        text="Überzeugen Sie sich noch heute von unseren Leistungen und nehmen Sie direkt Kontakt mit uns auf. Wir stehen Ihnen mit Rat und Tat zur Seite."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default ContentManagementSysteme;
